<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <!-- L2K L21详情 -->
        <div v-if="!showOrderItemInfo" class="detailL2k">
            <div class="content-left">
                <div class="content-box">
                    <div class="title">门店信息</div>
                    <div class="content">{{groupName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">订单号</div>
                    <div class="content">{{id}}</div>
                </div>
                <div class="content-box">
                    <div class="title">订单金额</div>
                    <div class="content">{{totalAmount}}</div>
                </div>
                <div class="content-box">
                    <div class="title">实际支付总金额</div>
                    <div class="content">{{realPayAmount}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品数量</div>
                    <div class="content">{{buyCounts}}</div>
                </div>
                <div class="content-box">
                    <div class="title">会员/消费者</div>
                    <div class="content">{{receiverName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">会员/消费者手机号</div>
                    <div class="content">{{phone}}</div>
                </div>
                <div class="content-box">
                    <div class="title">开柜门状态</div>
                    <div class="content">{{isTakeStr}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付方式</div>
                    <div class="content">{{payMethod}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商户平台订单号</div>
                    <div class="content">{{transactionNo}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付时间</div>
                    <div class="content">{{payTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付状态</div>
                    <div class="content">{{state}}</div>
                </div>
            </div>
            <div class="content-right">
                <div class="content-box">
                    <div class="title">下单时间</div>
                    <div class="content">{{createdTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品品类</div>
                    <div class="content">{{categoryName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品名称</div>
                    <div class="content">{{itemName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品编号</div>
                    <div class="content">{{itemCode}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品售价</div>
                    <div class="content">{{itemSellPrice}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品成本价</div>
                    <div class="content">{{itemCostPrice}}</div>
                </div>
                <div class="content-box" v-if="counterTypeVal != 6">
                    <div class="title">货柜MAC</div>
                    <div class="content">{{counterMac}}</div>
                </div>
                <div class="content-box" v-if="counterTypeVal == 6">
                    <div class="title">柜门信息</div>
                    <div class="content">{{counterInfo}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货管员</div>
                   <div class="content">{{counterUserName}}
                     <!-- <span v-show="seeMoreFlag" class="seeMore" @click="seeMore()">查看更多</span> -->
                     </div> 
                </div>
                <div class="content-box">
                    <div class="title">门店位置</div>
                    <div class="content">{{location}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货柜位置</div>
                    <div class="content">{{address}}</div>
                </div>
                <div class="content-box">
                    <div class="title"></div>
                    <div class="content"></div>
                </div>
            </div>
            <div class="content-box" style="display:flex;">
                <div class="title">商品描述</div>
                <div class="describeContent">
                    {{itemDetail}}
                </div>
            </div>
            <div class="content-box" style="display:flex;">
                <div class="title">商品图片</div>
                <div style="width:750px;">
                    <img class="productPicture" v-for="(item,index) in itemPicList" :key="index" :src="item" alt="">
                </div>
            </div>
            <!-- http://test-image.wavewisdom.com/group1/M00/00/B8/CgAOPGCeM_OAID_QAABcchcP6rQ558.jpg -->
            <!-- http://test-image.wavewisdom.com/group1/M00/00/9C/CgAOPGA4eR2AHScvAAIbK7xRfdI736.jpg -->
            <!-- _this.UPLOAD_URL -->

        </div>

        <!-- L22详情 -->
        <div v-if="showOrderItemInfo" class="detailL2k">
            <div class="content-left">
                <div class="content-box">
                    <div class="title">门店信息</div>
                    <div class="content">{{groupName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">主订单号</div>
                    <div class="content">{{id}}</div>
                </div>
                <div class="content-box">
                    <div class="title">订单总金额</div>
                    <div class="content">{{totalAmount}}</div>
                </div>
                <div class="content-box">
                    <div class="title">实际支付总金额</div>
                    <div class="content">{{realPayAmount}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商品数量（合计）</div>
                    <div class="content">{{buyCounts}}</div>
                </div>
                <div class="content-box">
                    <div class="title">会员/消费者</div>
                    <div class="content">{{receiverName}}</div>
                </div>
                <div class="content-box">
                    <div class="title">会员/消费者手机号</div>
                    <div class="content">{{phone}}</div>
                </div>
                <div class="content-box" v-if="counterTypeVal != 6">
                    <div class="title">货柜MAC</div>
                    <div class="content">{{counterMac}}</div>
                </div>
                <div class="content-box" v-if="counterTypeVal == 6">
                    <div class="title">柜门信息</div>
                    <div class="content">{{counterInfo}}</div>
                </div>


            </div>
            <div class="content-right">
                <div class="content-box">
                    <div class="title">下单时间</div>
                    <div class="content">{{createdTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付方式</div>
                    <div class="content">{{payMethod}}</div>
                </div>
                <div class="content-box">
                    <div class="title">商户平台订单号</div>
                    <div class="content">{{transactionNo}}</div>
                </div>
                <div class="content-box">
                    <div class="title">支付时间</div>
                    <div class="content">{{payTime}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货管员</div>
                    <div class="content">{{counterUserName}}
                        <!-- <span v-show="seeMoreFlag" class="seeMore" @click="seeMore()">查看更多</span> -->
                        </div>
                </div>
                <div class="content-box">
                    <div class="title">门店位置</div>
                    <div class="content">{{location}}</div>
                </div>
                <div class="content-box">
                    <div class="title">货柜位置</div>
                    <div class="content">{{address}}</div>
                </div>
            </div>
        </div>
        <div v-if="showOrderItemInfo">
            <div style="margin-bottom: 10px;padding-left:22px;">订单商品信息</div>
            <el-table :data="commodityList" height="390" style="width: 100%">
                <el-table-column prop="sunOrderNumber" align="center" label="子订单号">
                </el-table-column>
                <el-table-column prop="counterDoorName" align="center" :label="cabinetDoorName">
                </el-table-column>
                <el-table-column prop="itemImg" align="center" label="商品图片">
                    <template slot-scope="scope">
                        <img :src="scope.row.itemImg" style="height:100px;" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="categoryName" align="center" label="商品品类">
                </el-table-column>
                <el-table-column prop="deductRate" align="center" label="抽佣比例">
                </el-table-column>
                <el-table-column prop="itemName" align="center" label="商品名称">
                </el-table-column>
                <el-table-column prop="itemCode" align="center" label="商品编号">
                </el-table-column>
                <el-table-column prop="itemSellPrice" align="center" label="商品售价（元）">
                </el-table-column>
                <el-table-column v-if="notMerchant" prop="itemDistributorPrice" align="center" label="经销价（元）">
                </el-table-column>
                <el-table-column v-if="notDealer" prop="itemCostPrice" align="center" label="成本价（元）">
                </el-table-column>
                <!-- <el-table-column prop="itemDetail" align="center" label="商品详细描述">
                </el-table-column> -->
                <!-- L25  -->
                <el-table-column v-if="notMerchant && notSupply" prop="distributorName" align="center" label="经销商">
                </el-table-column>
                <el-table-column v-if="notDealer" prop="supplierName" align="center" label="供应商">
                </el-table-column>
                <el-table-column prop="stateStr" align="center" label="支付状态">
                </el-table-column>
                <el-table-column prop="pickUpStateStr" align="center" label="开柜门状态">
                </el-table-column>

            </el-table>
        </div>

        <!-- <el-dialog title="货管员" top="20vh" width="380px" :visible.sync="showMoreCounterUser" :close-on-click-modal="false">
            <div class="counterUserRespListBox">
                <div class="counterUserRespListBox_row" v-for="(item,index) in counterUserRespList" :key="index">
                    {{item.realName + '/' + item.phone}}<span v-show="item.masterUser">（主货管员）</span></div>
            </div>
        </el-dialog> -->
    </div>

</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                seeMoreFlag: false,
                counterUserRespList: [],
                showMoreCounterUser: false,
                showOrderItemInfo: false, //订单商品信息列表 L22详情显示 L21和L2k不显示
                title: "售货订单详情",
                itemPicList: [],
                id: "",
                totalAmount: "",
                realPayAmount: "",
                buyCounts: "",
                receiverName: "",
                phone: "",
                isTakeStr: "",
                payMethod: "",
                transactionNo: "",
                payTime: "",
                state: "",
                createdTime: "",
                categoryName: "",
                itemName: "",
                itemCode: "",
                version: "",
                counterMac: "",
                masterCounterUser: "",
                location: "",
                address: "",
                itemDetail: "",
                counterUserName: "",
                itemSellPrice: "",
                itemCostPrice: "",
                commodityList: [],
                groupName: "",
                counterInfo: "",
                counterTypeVal: "",
                cabinetDoorName: "货柜柜门",
                notDealer: true,
                notMerchant: true,
                notSupply: true
            };
        },
        created() {},
        activated() {
            console.log("detailL2k activated!!");
            let userInfo = this.cache.getLS("userInfo");
            let merchantFlag = this.getLoginType('loginType');
            console.log(userInfo,'-----merchantFlag');
            if(merchantFlag && userInfo.merchantType == 3){
                this.notDealer = false
            } else {
                this.notDealer = true
            }
            if(merchantFlag && userInfo.merchantType !== 3){
                this.notMerchant = false
            } else {
                this.notMerchant = true
            }
            if(userInfo.roleType == '13') {
                this.notSupply = false
            } else {
                this.notSupply = true
            }
            // L2k订单详情
            this.get("mall-service/orders/detail/" + this.$route.query.id).then(res => {
                this.counterTypeVal = res.counterType;
                console.log('this.counterTypeVal:', this.counterTypeVal)
                if(this.counterTypeVal == 20 || this.counterTypeVal == 21) {
                    this.cabinetDoorName = "货道编号";
                }else {
                    this.cabinetDoorName = "货柜柜门";
                }
                if ([3, 7, 8, 10, 16, 17, 18, 20, 21,2001,2006,2007,2008,2009,2010,2011,2012,2013].indexOf(this.counterTypeVal) != -1) {
                    //type=3是L22订单详情
                    this.showOrderItemInfo = true;
                } else {
                    this.showOrderItemInfo = false;
                }
                if (res.region) {
                    res.orderItemsRespList.forEach(it => {
                        it.counterDoorName = res.region + res.regionNumber + '-' + it.counterDoorName;

                    })
                }
                res.orderItemsRespList.forEach(item => {
                    if(this.counterTypeVal == 20 || this.counterTypeVal == 21) {
                        item.pickUpStateStr = ['开柜门失败', '开柜门成功'][item.isTake];
                    }else {
                        item.pickUpStateStr = ['开柜门失败', '开柜门成功'][item.isTake];
                    }
                    item.stateStr = ["未支付", "支付中", "支付成功", "支付失败", "订单取消", "支付超时", "支付成功已退款", "支付成功待退款", "支付成功退款失败"][item.state - 1];
                    item.sunOrderNumber = item.id;
                    item.deductRate = item.deductRate ? item.deductRate + '%' : '--'
                })
                this.commodityList = res.orderItemsRespList;
                this.id = res.id;
                this.totalAmount = res.totalAmount;
                this.realPayAmount = res.realPayAmount;
                this.buyCounts = res.buyCounts;
                this.receiverName = res.receiverName ? res.receiverName : '--';
                this.phone = res.phone ? res.phone : "--";

                if (res.payMethod == 1) {
                    this.payMethod = "微信支付";
                } 
                // else if (res.payMethod == 5) {
                //     this.payMethod = "微信支付（使用优惠券）";
                // } 
                else if (res.payMethod == 4) {
                    this.payMethod = "会员余额支付";
                } 
                else if (res.payMethod == 6) {
                    this.payMethod = "刷卡支付";
                } 
                // else if (res.payMethod == 7) {
                //     this.payMethod = "通联支付";
                // }
                this.transactionNo = res.transactionNo ? res.transactionNo : "--";
                this.payTime = res.payTime ? res.payTime : "--";
                this.state = ["未支付", "支付中", "支付成功", "支付失败", "订单取消", "支付超时", "支付成功已退款", "支付成功待退款", "支付成功退款失败"][res.state - 1];
                this.createdTime = res.createdTime;
                this.version = res.version;
                this.counterMac = res.counterMac;
                this.location = res.location ? res.location : '--';
                this.address = res.address ? res.address : '--';
                this.masterCounterUser = res.masterCounterUser ? res.masterCounterUser : [];
                this.counterUserName = res.masterCounterUser.realName + '/' + res.masterCounterUser.phone;
                // this.counterUserRespList = res.counterUserRespList ? res.counterUserRespList : [];
                // if (res.counterUserRespList && res.counterUserRespList.length > 0) {
                //     if(res.counterUserRespList.some(item => item.masterUser )){
                //             res.counterUserRespList.forEach(item =>{
                //                 if(item.masterUser ){
                //                     let counterUserRespListItem = item
                //                     this.counterUserName = counterUserRespListItem.realName + '/' + counterUserRespListItem.phone;

                //                 }
                //             })
                //         }else{
                //             let counterUserRespListItem = res.counterUserRespList[0];
                //             this.counterUserName = counterUserRespListItem.realName + '/' + counterUserRespListItem.phone;
                //         }
                //         this.seeMoreFlag = res.counterUserRespList.length > 1 ? true : false;
                //     }
                if (this.counterTypeVal == 1 || this.counterTypeVal == 2 || this.counterTypeVal == 6) {
                    this.categoryName = res.orderItemsRespList[0].categoryName;
                    this.itemName = res.orderItemsRespList[0].itemName;
                    this.itemCode = res.orderItemsRespList[0].itemCode;
                    this.itemSellPrice = res.orderItemsRespList[0].itemSellPrice;
                    this.itemCostPrice = res.orderItemsRespList[0].itemCostPrice;
                    this.itemPicList = res.orderItemsRespList[0].itemPicList;
                    this.itemDetail = res.orderItemsRespList[0].itemDetail ? res.orderItemsRespList[0]
                        .itemDetail : "--";
                    this.isTakeStr = ['未取货已退款', '已取货'][res.orderItemsRespList[0].isTake];
                }
                if (res.groupName) {
                    if (res.region) {
                        this.groupName = res.groupName + ' ' + res.region + res.regionNumber;
                    } else {
                        this.groupName = res.groupName;
                    }
                } else {
                    this.groupName = '--';
                }
                if(this.counterTypeVal == 6) {
                    this.counterInfo = (res.groupName ? res.groupName : '') + res.orderItemsRespList[0].counterDoorName;
                }
            });


        },
        mounted() {},
        methods: {
            seeMore() {
                this.showMoreCounterUser = true;
            },
        }
    };
</script>
<style lang="scss" scoped>
    .detailL2k {
        margin: auto;
        width: 1000px;

        .content-left,
        .content-right {
            display: inline-block;
            width: 500px;
        }

        .content-box {
            margin: 20px 0;
            font-size: 15px;
            display: flex;

            .title {
                display: inline-block;
                padding-right: 40px;
                text-align: right;
                width: 200px;
            }

            .content {
                color: #666;
                display: inline-block;
                width: 260px;
                word-wrap: break-word;
            }

            .seeMore {
                color: #30BAC1;
                cursor: pointer;
                padding-left: 10px;
            }

            .describeContent {
                display: inline-block;
                width: 750px;
                word-wrap: break-word;
            }

            .productPicture {
                width: 200px;
                height: 130px;
                margin: 8px;
            }
        }
    }

    .counterUserRespListBox {
        height: 180px;
        overflow-y: auto;
    }

    .counterUserRespListBox_row {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #EBEEF5;
        padding-left: 57px;
    }
</style>