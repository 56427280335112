var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      !_vm.showOrderItemInfo
        ? _c("div", { staticClass: "detailL2k" }, [
            _c("div", { staticClass: "content-left" }, [
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("门店信息")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.groupName))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单号")]),
                _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.id))])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单金额")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.totalAmount))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("实际支付总金额")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.realPayAmount))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品数量")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.buyCounts))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("会员/消费者")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.receiverName))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("会员/消费者手机号")
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.phone))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("开柜门状态")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.isTakeStr))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("支付方式")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.payMethod))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商户平台订单号")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.transactionNo))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("支付时间")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.payTime))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("支付状态")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.state))
                ])
              ])
            ]),
            _c("div", { staticClass: "content-right" }, [
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("下单时间")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.createdTime))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品品类")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.categoryName))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品名称")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.itemName))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品编号")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.itemCode))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品售价")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.itemSellPrice))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商品成本价")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.itemCostPrice))
                ])
              ]),
              _vm.counterTypeVal != 6
                ? _c("div", { staticClass: "content-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("货柜MAC")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.counterMac))
                    ])
                  ])
                : _vm._e(),
              _vm.counterTypeVal == 6
                ? _c("div", { staticClass: "content-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("柜门信息")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.counterInfo))
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("货管员")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.counterUserName) + "\n                 ")
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("门店位置")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.location))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("货柜位置")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.address))
                ])
              ]),
              _vm._m(0)
            ]),
            _c(
              "div",
              { staticClass: "content-box", staticStyle: { display: "flex" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("商品描述")]),
                _c("div", { staticClass: "describeContent" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.itemDetail) +
                      "\n            "
                  )
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "content-box", staticStyle: { display: "flex" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("商品图片")]),
                _c(
                  "div",
                  { staticStyle: { width: "750px" } },
                  _vm._l(_vm.itemPicList, function(item, index) {
                    return _c("img", {
                      key: index,
                      staticClass: "productPicture",
                      attrs: { src: item, alt: "" }
                    })
                  }),
                  0
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.showOrderItemInfo
        ? _c("div", { staticClass: "detailL2k" }, [
            _c("div", { staticClass: "content-left" }, [
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("门店信息")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.groupName))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("主订单号")]),
                _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.id))])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单总金额")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.totalAmount))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("实际支付总金额")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.realPayAmount))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("商品数量（合计）")
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.buyCounts))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("会员/消费者")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.receiverName))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("会员/消费者手机号")
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.phone))
                ])
              ]),
              _vm.counterTypeVal != 6
                ? _c("div", { staticClass: "content-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("货柜MAC")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.counterMac))
                    ])
                  ])
                : _vm._e(),
              _vm.counterTypeVal == 6
                ? _c("div", { staticClass: "content-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("柜门信息")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.counterInfo))
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "content-right" }, [
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("下单时间")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.createdTime))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("支付方式")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.payMethod))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("商户平台订单号")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.transactionNo))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("支付时间")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.payTime))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("货管员")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.counterUserName) + "\n                    ")
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("门店位置")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.location))
                ])
              ]),
              _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("货柜位置")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.address))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.showOrderItemInfo
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "padding-left": "22px"
                  }
                },
                [_vm._v("订单商品信息")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.commodityList, height: "390" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "sunOrderNumber",
                      align: "center",
                      label: "子订单号"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "counterDoorName",
                      align: "center",
                      label: _vm.cabinetDoorName
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "itemImg",
                      align: "center",
                      label: "商品图片"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              staticStyle: { height: "100px" },
                              attrs: { src: scope.row.itemImg, alt: "" }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "categoryName",
                      align: "center",
                      label: "商品品类"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deductRate",
                      align: "center",
                      label: "抽佣比例"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "itemName",
                      align: "center",
                      label: "商品名称"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "itemCode",
                      align: "center",
                      label: "商品编号"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "itemSellPrice",
                      align: "center",
                      label: "商品售价（元）"
                    }
                  }),
                  _vm.notMerchant
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "itemDistributorPrice",
                          align: "center",
                          label: "经销价（元）"
                        }
                      })
                    : _vm._e(),
                  _vm.notDealer
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "itemCostPrice",
                          align: "center",
                          label: "成本价（元）"
                        }
                      })
                    : _vm._e(),
                  _vm.notMerchant && _vm.notSupply
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "distributorName",
                          align: "center",
                          label: "经销商"
                        }
                      })
                    : _vm._e(),
                  _vm.notDealer
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "supplierName",
                          align: "center",
                          label: "供应商"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stateStr",
                      align: "center",
                      label: "支付状态"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pickUpStateStr",
                      align: "center",
                      label: "开柜门状态"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }),
      _c("div", { staticClass: "content" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }